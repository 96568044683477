export default {
  mainLighter: "#F7F9F9",
  mainLight: "#F8F9F9",
  main: "#F2F3F4",

  secondaryLighter: "#F2F2F2",
  secondaryLight: "#CCCECF",
  secondary: "#9C9FA3",
  secondaryDark: "#6F7378",
  secondaryDarken: "#454A50",
  techDarkBlue: "#1F252B",

  textWhite: "#FFFFFF",
  textSecondaryLighter: "#F2F2F2",
  textSecondaryLight: "#CCCECF",
  textSecondary: "#9C9FA3",
  textSecondaryDark: "#6F7378",
  textSecondaryDarken: "#454A50",
  text: "#1F252B",

  accentLighter: "#D6EAF8",
  accentLight: "#85C1E9",
  accent: "#3162FF",
  accentSecondary: "#012C61",
  branding: "#3162FF",

  purpleLighter: "#E8DAEF",
  purpleLight: "#BB8FCE",
  purple: "#8E44AD",

  successLighter: "#D4EFDF",
  successLight: "#7DCEA0",
  success: "#27AE60",

  orangeLighter: "#FDEBD0",
  orangeLight: "#F8C471",
  orange: "#F39C12",

  errorLighter: "#FADBD8",
  errorLight: "#F1948A",
  error: "#E74C3C",

  yellowLighter: "#FCF3CF",
  yellowLight: "#F7DC6F",
  yellow: "#F1C40F",
};
